
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { ShowDialog } from "@/helper";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const router = useRouter();
    const items = ["General Inquiry", "RISN", "CBDC", "Merchant"];
    const data = reactive({
      name: "",
      op: "",
      city: "",
      email: "",
      country: "",
      merchant: "",
      code: "+1",
      phone: "",
      merchantemail: "",
      message: "",
    });
    const resetdata = () => {
      data.name = "";
      data.op = "";
      data.city = "";
      data.email = "";
      data.country = "";
      data.merchant = "";
      data.code = "";
      data.phone = "";
      data.merchantemail = "";
      data.message = "";
    };
    const _load_script = function (url: string, callback: any) {
      let head: any = document.querySelector("head");
      let script: any = document.createElement("script");
      let r = false;
      script.type = "text/javascript";
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == "complete")) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };
    const win: any = window;
    win._show_thank_you = () => {
      ShowDialog("Thanks for signing up!");
    };
    win._show_error = (id: string, message: string) => {
      ShowDialog(message);
    };
    _load_script(
      "//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
      function () {
        win._form_serialize = win.serialize;
        console.log(win._form_serialize);
      }
    );
    let loading = false;
    const handsubmit = () => {
      if (loading) return;
      loading = true;
      // check data
      const reg = new RegExp(`^(\\w+\\.*\\w+)*\\@(\\w+\\.*\\w+)*$`);
      if (!reg.test(data.email)) {
        loading = false;
        ShowDialog("E-mail address is invalid");
        return;
      }
      let form_name = "_form_17_";
      var serialized = win
        ._form_serialize(document.getElementById(form_name))
        .replace(/%0A/g, "\\n");
      console.log(`json:${serialized}`);
      _load_script(
        "https://apifiny.activehosted.com/proc.php?" + serialized + "&jsonp=true",
        () => {
          //reset date
          resetdata();
          loading = false;
        }
      );
    };
    const phonenum = computed(() => {
      return `${data.code}${data.phone}`;
    });
    const gotolink = (url = "/contact") => {
      router.push(url);
    };
    return { gotolink, phonenum, handsubmit, bkcolor, data, items };
  },
});
